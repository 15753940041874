import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Intro from './Intro.png'

class GatherHere extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hidden: "hidden" };
    }

      componentWillMount = () => {
        var that = this;
        setTimeout(function() {
            that.show();
        }, 5000);
    }
    show = () => {
        this.setState({hidden : ""});
    }

    render() {
        return  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Stack container spacing={3} alignItems="center" justifyContent="center" >
                        <img className={this.state.hidden} src={Intro} alt="gather here"/>
                    </Stack>
                </Paper>
  }
}

export default GatherHere
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import GatherHere from './GatherHere';

class ChristmasMorning extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
      }
    
    yesClick = () => {

    }

    noClick = () => {

    }

    render() {
        return  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Stack container spacing={3} alignItems="center" justifyContent="center" >
                        <Typography variant="h5" color="text.primary" align="center">
                            {'Are you Morgan?'}
                            <GatherHere />
                        </Typography>
                    </Stack>
                </Paper>
  }
}

export default ChristmasMorning
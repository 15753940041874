import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
  } from "@mui/material";
  import { makeStyles } from "@mui/styles"
  import MenuIcon from "@mui/icons-material/Menu";
  import React, { useState, useEffect } from "react";
  import LoginLogout from "./LoginLogout"
  
  const useStyles = makeStyles({
    header: {
      backgroundColor: "primary",
      paddingRight: "79px",
      paddingLeft: "118px",
      "@media (max-width: 900px)": {
        paddingLeft: 0,
      },
    },
    logo: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 600,
      color: "#FFFEFE",
      textAlign: "left",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    drawerContainer: {
      padding: "20px 30px",
    },
  });
  
  export default function Header(props) {
    const { header, logo, toolbar, drawerContainer } = useStyles();
  
    const [state, setState] = useState({
      mobileView: false,
      drawerOpen: false,
      userLoggedIn: props.loggedIn
    });
  
    const { mobileView, drawerOpen, userLoggedIn } = state;
  
    useEffect(() => {
      const setResponsiveness = () => {
        return window.innerWidth < 900
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
      setResponsiveness();
  
      window.addEventListener("resize", () => setResponsiveness());
  
      return () => {
        window.removeEventListener("resize", () => setResponsiveness());
      };
    }, []);
  
    const displayDesktop = () => {
      return (
        <Toolbar className={toolbar}>
          {pbLogo}
          <div>{getMenuButtons()}</div>
        </Toolbar>
      );
    };
  
    const displayMobile = () => {
      const handleDrawerOpen = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: true }));
      const handleDrawerClose = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
  
      return (
        <Toolbar>
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
          </IconButton>
  
          <Drawer
            {...{
              anchor: "left",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>{getDrawerChoices()}</div>
          </Drawer>
  
          <div>{pbLogo}</div>
        </Toolbar>
      );
    };

    const loginSuccess = (login_id_token) => {
      props.onLoginCallback(login_id_token);
    }

    const logoutSuccess = () => {
      props.onLogoutCallback();
    }

    const getDrawerChoices = () => {
      return (
        <LoginLogout />
      );
    };
  
    const pbLogo = (
      <Typography variant="h6" component="h1" className={logo}>
        Pattern Bin
      </Typography>
    );
  
    const getMenuButtons = () => {
      return ;
        //<LoginLogout onLoginSuccessCallback={loginSuccess} onLogoutSuccessCallback={logoutSuccess} loggedIn={userLoggedIn} />
    };
  
    return (
      <header>
        <AppBar className={header}>
          {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
      </header>
    );
  }
  
import * as React from 'react';
import { GoogleLogin, GoogleLogout } from "react-google-login"

class LoginLogout extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loggedIn: this.props.loggedIn };
      }

    loginSuccess = (response) => {
        var id_token = response.getAuthResponse().id_token;
        this.props.onLoginSuccessCallback(id_token);
        // console.log(id_token);
        this.setState({
            loggedIn: true
          });
    }

    failure = (response) => {
        //console.log(response);
      }
    
    logoutSuccess = () => {
        this.props.onLogoutSuccessCallback();
        this.setState({
            loggedIn: false
          });
    }

    render() {
        if (this.state.loggedIn) {
            return <GoogleLogout
                clientId="269274648664-arveomld6t19l3raset7db0k07lhcrcs.apps.googleusercontent.comm"
                buttonText="Logout"
                onLogoutSuccess={this.logoutSuccess}
            ></GoogleLogout>
        }
        else {
            return <GoogleLogin
                clientId="269274648664-arveomld6t19l3raset7db0k07lhcrcs.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={this.loginSuccess}
                onFailure={this.failure}
                cookiePolicy={'single_host_origin'}
            />
        }
    }

    // componentWillUnmount() {
    //     this
    // }
  }

export default LoginLogout
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LoginLogout from "./LoginLogout"

class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    loginSuccess = (login_id_token) => {
        this.props.onLoginCallback(login_id_token);
    }

    logoutSuccess = () => {
        this.props.onLogoutCallback();
    }

    render() {
        return  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Stack container spacing={3} alignItems="center" justifyContent="center" >
                        <Typography variant="h7" color="text.secondary" align="center">
                            {'Have you ever been standing in JoAnn\'s or your favorite local sewing shop and said to yourself "I know this pattern is only $.99 but I wish I knew if I had this at home"'}
                        </Typography>
                        <Typography variant="h7" color="text.secondary" align="center">
                            {"Log in to start searching those bins full of patterns in your sewing room"}
                        </Typography>
                        <LoginLogout onLoginSuccessCallback={this.loginSuccess} onLogoutSuccessCallback={this.logoutSuccess} loggedIn={false} />
                    </Stack>
                </Paper>
  }
}

export default Landing
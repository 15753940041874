import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Header from './Header';
import PatternChecker from './PatternChecker';
import Landing from './Landing';
import ChristmasMorning from './ChristmasMorning';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const mdTheme = createTheme({
  palette: {
    primary: {
      light: '#fd558f',
      main: '#c51162',
      dark: '#8e0038',
      contrastText: '#fff',
    },
    secondary: {
      light: '#428e92',
      main: '#006064',
      dark: '#00363a',
      contrastText: '#fff',
    },
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userLoggedIn: false, userIdToken: "" };
  }

  login = (login_id_token) => {
    this.setState((state, props) => {
      return {userLoggedIn: true, userIdToken: login_id_token};
    });
  }

  logout = () => {
    this.setState((state, props) => {
      return {userLoggedIn: false, userIdToken: ""};
    });
  }

  mainContent = () => {
    if (this.state.userLoggedIn) {
      return <PatternChecker userIdToken={this.state.userIdToken} />
    }
    else {
      //return <ChristmasMorning />
      return <Landing onLoginCallback={this.login} onLogoutCallback={this.logout} loggedIn={false} />
    }
  }

  render() {
    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Header onLoginCallback={this.login} onLogoutCallback={this.logout} loggedIn={this.state.userLoggedIn} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="xs" sx={{ mt: 4, mb: 4 }}>
              {this.mainContent()}
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default Dashboard

import Dashboard from './Dashboard';

import { Provider, ErrorBoundary } from '@rollbar/react'; 

import './App.css';
import 'antd/dist/antd.css';

const rollbarConfig = {
  accessToken: '47fc70cdd4e4432f9e729720d298f3e8',
  environment: 'production',
  captureUncaught: true,
  autoInstrument: true,
  server: {
    root: "https://pattern-bin.com/",
    branch: "main"
    },
    code_version: "0.0.1"
}


function App() {
  return (
    /* Provider instantiates Rollbar client instance handling any uncaught errors or unhandled promises in the browser */
    <Provider config={rollbarConfig}>
      {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
      <ErrorBoundary errorMessage={"Some error"}>
        <div className="App">
          <Dashboard />
        </div>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;

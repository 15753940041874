import * as React from 'react';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import AddAPhotoSharpIcon from '@mui/icons-material/AddAPhotoSharp';
import Divider from '@mui/material/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'
import { Context, getRollbarFromContext } from '@rollbar/react';

const Input = styled('input')({
    display: 'none',
  });

class PatternChecker extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = { 
            userIdToken: this.props.userIdToken,
            loadingFromApiCheck: false,
            loadingFromApiAdd: false,
            hasSearched: false,
            patternAdded: false,
            checkError: false,
            addError: false
        };
      }
    
    uploadPhoto = (event) => {
        this.setState({
            loadingFromApiCheck: true,
            checkError: false
        })

        var rollbar = getRollbarFromContext(this.context);

        var apiHeaders = new Headers();
        apiHeaders.append('Authorization', 'Bearer ' + this.state.userIdToken);

        var data = new FormData();
        data.append( "upcImage", event.target.files[0] );
        var url = process.env.REACT_APP_API_HOSTNAME + "/patterns/check";
        fetch(url,
        {
            headers: apiHeaders,
            method: "POST",
            body: data
        })
        .then(response => {
            response.json().then(responseJson => {
                if (!response.ok) {
                    if (response.status === 400) {
                        if (responseJson.title === "Unreadable") {
                            this.setState({
                                checkError: true,
                                checkErrorMessage: "Unable to read a barcode in the image, please try a different photo",
                                hasSearched: false,
                                loadingFromApiCheck: false
                            });
                        }
                        else if (responseJson.title === "Error") {
                            this.setState({
                                checkError: true,
                                checkErrorMessage: "There was an error reading the image, try again or try a different photo",
                                hasSearched: false,
                                loadingFromApiCheck: false
                            });
                        }
                        else {
                            this.setState({
                                checkError: true,
                                checkErrorMessage: "There was an unknown error reading the image, try again or try a different photo",
                                hasSearched: false,
                                loadingFromApiCheck: false
                            });
                           
                            rollbar.error(response);
                        }
                    }
                    else if (response.status === 500) {
                        this.setState({
                            checkError: true,
                            checkErrorMessage: "Server error: " + response.text(),
                            hasSearched: false,
                            loadingFromApiCheck: false
                        });

                        rollbar.error(response);
                    }
                }
                else {
                    this.setState({
                        hasSearched: !this.state.checkError && true,
                        result: responseJson,
                        patternAdded: false,
                        loadingFromApiCheck: false
                    });

                    rollbar.error(responseJson);
                }
            })
        })
        .catch(error => {
            this.setState({
                checkError: true,
                checkErrorMessage: error + " / " + this.state.checkErrorMessage
            });

            rollbar.error(error);
        })
    }

    addPattern = () => {
        this.setState({
            loadingFromApiAdd: true
        })

        var rollbar = getRollbarFromContext(this.context);

        var apiHeaders = new Headers();
        apiHeaders.append('Authorization', 'Bearer ' + this.state.userIdToken);

        var data = new FormData();
        data.append( "upc", this.state.result.pattern.upc);
        var url = process.env.REACT_APP_API_HOSTNAME + "/patterns";
        fetch(url,
        {
            headers: apiHeaders,
            method: "PUT",
            body: data
        })
        .then(response => {
            response.json().then(responseJson => {
                if (!response.ok) {
                    if (response.status === 400) {
                        if (responseJson.title === "NullPattern") {
                            this.setState({
                                addError: true,
                                addErrorMessage: "Error adding pattern, pattern was empty",
                                loadingFromApiAdd: false
                            });
                        }
                        else if (responseJson.title === "Unexpected") {
                            this.setState({
                                addError: true,
                                addErrorMessage: "Unexpected error adding pattern to bin",
                                loadingFromApiAdd: false
                            });

                            rollbar.error(response);
                        }
                        else {
                            this.setState({
                                addError: true,
                                addErrorMessage: "Unknown error adding pattern to bin",
                                loadingFromApiAdd: false
                            });

                            rollbar.error(response);
                        }
                    }
                    else if (response.status === 500) {
                        this.setState({
                            addError: true,
                            addErrorMessage: "Server error: " + response.text(),
                            loadingFromApiAdd: false
                        });

                        rollbar.error(response);
                    }
                }
                else {
                    this.setState({
                        hasSearched: true,
                        patternAdded: true,
                        loadingFromApiAdd: false
                    });

                    rollbar.error(responseJson);
                }
            })
            if (!response.ok) {
                if (response.status === 400) {
                    this.setState({
                        addError: true,
                        addErrorMessage: "Error adding pattern, pattern was null"
                    });
                }
                else if (response.status === 500) {
                    this.setState({
                        addError: true,
                        addErrorMessage: "Unexpected server error: " + response.text()
                    });
                    rollbar.error(response);
                }
                else {
                    this.setState({
                        addError: true,
                        addErrorMessage: "Unexpected server error: " + response.text()
                    });
                    rollbar.error(response);
                }
            }
            
            return response;
        })
        .catch(error => {
            this.setState({
                addError: true,
                addErrorMessage: error + " / " + this.state.addErrorMessage,
                loadingFromApiAdd: false
            });

            rollbar.error(error);
        })
    }

    render() {
        return  <Stack container spacing={3} alignItems="center" justifyContent="center" >
                    <Paper sx={{ p: 2 }}>
                        <Stack container spacing={3} alignItems="center" justifyContent="center" >
                            <Typography variant="h7" color="text.primary" align="center">
                                {'Check for a pattern by uploading a photo of the UPC code or entering the UPC below'}
                            </Typography>
                            { this.state.checkError && 
                                <Typography variant="subtitle2" color="error" align="center">
                                    { this.state.checkErrorMessage }
                                </Typography>
                            }
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={this.uploadPhoto}/>
                                <LoadingButton variant='contained' color="primary" loading={this.state.loadingFromApiCheck} sx={{ fontSize: "medium" }} endIcon={<AddAPhotoSharpIcon color="text.secondary" />} component="span">
                                    Upload
                                </LoadingButton>
                            </label>
                            <Typography variant="h6" color="text.secondary" align="center">
                                {'or'}
                            </Typography>
                            <TextField
                                label="Enter UPC code"
                                id="outlined-start-adornment"
                                sx={{ m: 1}}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start"><FontAwesomeIcon icon={faBarcode} size="lg"/></InputAdornment>,
                                }}
                            />
                        </Stack>
                    </Paper>
                    { this.state.hasSearched && 
                        <Paper sx={{ p: 2 }}>
                            <Stack container spacing={3} alignItems="center" justifyContent="center" >
                                { this.state.addError && 
                                    <Typography variant="subtitle2" color="error" align="center">
                                        { this.state.addErrorMessage }
                                    </Typography>
                                }
                                { this.state.result.exists && 
                                    <Typography variant="h5" color="text.primary" align="center">
                                        {'Already in Bin'}
                                        <CheckBoxSharpIcon fontSize="large" color="success" sx={{ margin: "0px 0px -7px 3px"}}/>
                                    </Typography> 
                                }
                                { !this.state.result.exists && !this.state.patternAdded &&
                                    <LoadingButton variant='contained' color="primary" loading={this.state.loadingFromApiAdd} sx={{ fontSize: "medium", margin: "0 0 5px 5px" }} component="span" onClick={this.addPattern} endIcon={<AddSharpIcon color="text.secondary" />}>
                                        Add to Bin
                                    </LoadingButton> 
                                }
                                { this.state.patternAdded &&
                                    <Typography variant="h5" color="text.primary" align="center">
                                        { "Added" }
                                        <CheckBoxSharpIcon fontSize="large" color="success" sx={{ margin: "0px 0px -7px 3px"}}/>
                                    </Typography>
                                }
                                <Divider variant="fullWidth" flexItem >
                                    <Typography variant="h6" color="text.secondary" align="center">
                                        { "Pattern" }
                                    </Typography>
                                </Divider>

                                <Typography variant="subtitle1" color="text.secondary" align="center">
                                    { "UPC: " } { this.state.result.pattern.upc }
                                </Typography>
                            </Stack>
                        </Paper>
                    }
                </Stack>
  }
}

export default PatternChecker